import { module } from 'modujs';
import { gsap } from 'gsap'

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.logoTL = gsap.timeline({
            defaults: {
                ease: "none",
                duration: 1
            },
            onComplete: () => {

            }
        })
        this.logoTL.addLabel('start')
        this.logoTL.fromTo(this.$('shape')[0], { y: '-90%' }, { y: 0 }, 'start')
        // this.logoTL.fromTo(this.$('shape')[1], { y: '-50%' }, { y: 0 }, 'start') WE SKIP THE SECOND SHAPE
        this.logoTL.fromTo(this.$('shape')[2], { y: '-165%' }, { y: 0 }, 'start')
        this.logoTL.fromTo(this.$('shape')[3], { y: '-60%' }, { y: 0 }, 'start')
        this.logoTL.pause()
    }

    onScroll(param) {
        let progress = param[0].progress

        //update progress of UI
        this.logoTL.progress(progress*2)
    }
}
