import { module } from 'modujs';
import { Swiper, Navigation } from 'swiper';
import { gsap } from 'gsap';
import { lerp } from '../utils/maths'

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        Swiper.use([Navigation]);

        this.container = this.$('container')[0];

        this.carousel = new Swiper(this.container, {
            speed: 900,
            loop: true,
            spaceBetween: 0,
            grabCursor: true,
            spaceBetween: 0,
            slidesPerView: 2,
            threshold: 3,
            navigation: {
                prevEl: this.$('prev')[0],
                nextEl: this.$('next')[0]
            },
            breakpoints: {
                1200: {
                    slidesPerView: 4
                },
                700: {
                    slidesPerView: 3
                }
            }
        });

        this.controls = this.$('controls');

        if(this.controls.length) {
            this.initControls()
        }
    }

    initControls() {
        if(window.isMobile) return

        this.controlsInner = this.$('controls-inner')[0];

        this.position = { y: 0 }
        this.mouse = { y: 0 }
        this.lerpedComputedPosition = 0

        this.mouseenterBind = () => { this.animateControls() }

        this.mouseleaveBind = () => {
            cancelAnimationFrame(this.raf)

            this.position = { y: 0 }
            this.mouse = { y: 0 }
            this.lerpedComputedPosition = 0

            gsap.to(this.controlsInner, 0.35, { y:0 })
        }

        this.mousemoveBind = (e) => {
            this.mouse.y = e.clientY
        }

        this.el.addEventListener('mouseenter', this.mouseenterBind.bind(this))
        this.el.addEventListener('mouseleave', this.mouseleaveBind.bind(this))
        this.el.addEventListener('mousemove', this.mousemoveBind.bind(this))
    }

    animateControls() {
        this.controlsBCR    = this.compute(this.controls[0])
        this.parentsBCR     = this.compute(this.controls[0].parentElement)

        this.position.y     = this.controlsBCR.y + (this.controlsBCR.height / 2)

        this.computedPosition = this.position.y - this.mouse.y
        if (this.computedPosition <= 0) this.computedPosition = 0
        if (this.computedPosition + this.controlsBCR.height >= this.parentsBCR.height) this.computedPosition = this.parentsBCR.height - (this.controlsBCR.height)

        this.lerpedComputedPosition = lerp(this.lerpedComputedPosition, this.computedPosition, 0.2)

        gsap.set(this.controlsInner, { y: -this.lerpedComputedPosition })

        this.raf = requestAnimationFrame(() => this.animateControls())
    }

    compute(el) {
        return el.getBoundingClientRect();
    }

    destroy() {
        super.destroy()

        if(this.carousel && this.carousel.destroy) this.carousel.destroy()

        if(window.isMobile) return
        this.el.removeEventListener('mouseenter', this.mouseenterBind.bind(this))
        this.el.removeEventListener('mouseleave', this.mouseleaveBind.bind(this))
        this.el.removeEventListener('mousemove', this.mousemoveBind.bind(this))
    }
}
