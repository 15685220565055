import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';
import { html } from '../utils/environment'
import { queryClosestParent } from '../utils/html'

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        setTimeout(() => {
            this.scroll = new LocomotiveScroll({
                el: this.el,
                smooth: true,
                getDirection: true,
            });

            this.scroll.on('call', (func,way,obj,id) => {
                // Using modularJS
                this.call(func[0],{way,obj},func[1],func[2]);
            });

            this.scroll.on('scroll', (instance) => {
                window.scrollObj = instance

                if(instance.scroll.y > 80) {
                    html.classList.add('has-scrolled');
                } else {
                    html.classList.remove('has-scrolled');
                }

                if(typeof instance.currentElements['logoAnimation'] === 'object') {
                    let progress = instance.currentElements['logoAnimation'];
                    this.call('onScroll', [progress, instance], 'LogoAnimation');
                }
            })
        }, 300)
    }

    toggleLazy(args) {
        let src = this.getData('lazy', args.obj.el)
        if(src.length) {
            let img = new Image()

            let loadCallback = () => {
                if(args.obj.el.tagName == "IMG") {
                    args.obj.el.src = src
                } else {
                    args.obj.el.style.backgroundImage = `url(${src})`
                }

                requestAnimationFrame(() => {
                    requestAnimationFrame(() => {
                        let lazyParent = queryClosestParent(args.obj.el, '.c-figure-lazy')
                        if(lazyParent) lazyParent.classList.add('is-loaded')
                        args.obj.el.classList.add('is-loaded')
                    })
                })
            }

            // Try to use `decode` if available (to prevent jank), otherwise use the standard `onload`
            // setTimeout(() => {
                if(img.decode) {
                    img.src = src
                    img.decode().then(loadCallback).catch(err => {
                        console.error(err)
                    })
                } else {
                    img.onload = loadCallback
                    img.src = src
                }
            // }, 2000);
        }
    }


    destroy() {
        html.classList.remove('has-scrolled');
        this.scroll.destroy();
    }
}
