import { module } from 'modujs';
import { html } from '../utils/environment'

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: 'toggle'
        }
    }

    init() {

    }

    toggle() {
        if(html.classList.contains('is-vibrating')) {
            html.classList.remove('is-vibrating')
        } else {
            html.classList.add('is-vibrating')
        }
    }

    destroy() {
        super.destroy()

        // html.classList.remove('is-vibrating')
    }
}
