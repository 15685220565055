import { module } from 'modujs';
import { html } from '../utils/environment';
import modularLoad from 'modularload';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.load = new modularLoad({
            enterDelay: 400,
            transitions: {
                customTransition: {}
            }
        });

        this.load.on('loading', (transition, oldContainer) => {
            this.call('close', null, 'Menu')
        });

        this.load.on('loaded', (transition, oldContainer, newContainer) => {
            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');
        });
    }

    goTo(param) {
        console.log(param)
        this.load.goTo(param[0], param[1])
    }
}
