import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                button: 'handleClick'
            }
        }
    }

    init() {

    }

    handleClick(e) {
        let value = this.$('input')[0].value;

        if(value === "") {
            this.$('input')[0].focus()
        } else {
            e.preventDefault();
            this.call('goTo', [`/search.php?search=${value}`, null], 'Load')
        }
    }
}
