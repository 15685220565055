import { module } from 'modujs';
import { html } from '../utils/environment'

const CLASS = {
    OPENNAV: `has-nav-open`
}

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                toggler: 'toggleNav'
            }
        }
    }

    init(){
        this.isOpen = false
    }

    toggleNav() {
        if(this.isOpen) {
            this.close()
        } else {
            this.open()
        }
    }

    open() {
        this.isOpen = true

        html.classList.add(CLASS.OPENNAV)
    }

    close() {
        this.isOpen = false

        html.classList.remove(CLASS.OPENNAV)
    }
}
