import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.video = this.$('video')[0]
    }

    toggleInview(e) {
        if(e.way === "enter") {
            this.video.play()
        } else {
            this.video.pause()
        }
    }
}
